import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';

import { getIconComp } from '../backend/categoriesIcon';
import SEO from '../comp/seo';
import HeadNav from '../comp/headNav';
import NavigationLink from '../comp/navigationLink';
import CardBox from '../comp/cardBox';
import 'highlight.js/styles/default.css';

import '../styles/page.scss';
var hljs = require('highlight.js');
const md = require('markdown-it')();
var linkify = require('linkify-it')();

// md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
//     const href = tokens[idx].attrGet('href');
//     if (href && linkify.test(href)) {
//         tokens[idx].attrSet('target', '_blank');
//     }
//     return self.renderToken(tokens, idx, options);
// };

const APIDetails = ({ data }) => {
    const apiInfo = data.single.nodes[0];

    const {
        title,
        website,
        description,
        category,
        category_id,
        keywords,
        slug,
        content,
        logo,
    } = apiInfo;
    const similarAPIs = data.apisArray.nodes;

    function removeAPIWord(title) {
        return title.replace('API', '');
    }

    const apiTitle = `${removeAPIWord(title)}`;

    useEffect(() => {
        hljs.highlightAll();
    }, []);

    return (
        <>
            <HeadNav />
            <div className="container">
                <SEO
                    title={`${apiTitle} Key Performance Indicator —  KPI Directory by InsightWorthy`}
                    description={description}
                    keywords={keywords}
                />
                <div className="singlePageLayout">
                    <main className="main">
                        <div className="apiPage">
                            <div className="pageHead">
                                <img
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                    }}
                                    loading="lazy"
                                    src={logo}
                                    alt={title}
                                />
                                <div className="pageMeta">
                                    <NavigationLink />

                                    <h2 className="title">{title}</h2>
                                    <ul>
                                        <li>
                                            {getIconComp(category_id)}{' '}
                                            <span>Category: {category}</span>
                                        </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <a href={website} target="_blank">
                                        <button
                                            style={{
                                                padding: '5px 10px',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            → Learn More About InsightWorhy
                                        </button>
                                    </a>
                                    {/* <p>{description}</p> */}
                                    <div
                                        className="markdown-p"
                                        dangerouslySetInnerHTML={{
                                            __html: md.render(content),
                                        }}
                                    />

                                    <a
                                        href={website}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            color: '#fff',
                                        }}
                                    >
                                        <span>{`Visit to ${title} website`}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                    <main className="main">
                        <NavigationLink />
                        <h2 className="pageTitle">
                            Similar KPIs of {category}
                        </h2>
                        <div className="api-list">
                            {similarAPIs.map((api, key) => (
                                <CardBox key={key} api={api} cardKey={key} />
                            ))}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default APIDetails;

export const pageQuery = graphql`
    query($slug_id: String, $cat_id: String) {
        single: allPaApis(filter: { slug: { eq: $slug_id } }) {
            nodes {
                id
                published_at
                title
                website
                description
                category
                category_id
                slug
                keywords
                content
                createdAt
                logo
            }
        }
        apisArray: allPaApis(
            limit: 10
            filter: { category_id: { eq: $cat_id }, slug: { ne: $slug_id } }
        ) {
            nodes {
                id
                category_id
                category
                published_at
                description
                website
                slug
                title
                createdAt
                logo
                keywords
            }
        }
    }
`;
