import React from 'react';
import { Link } from 'gatsby';
import { BsArrowLeftSquare } from 'react-icons/bs';

const NavigationLink = () => {
      return (
            <div className="navigation-link">
                  <Link to="/">
                        <BsArrowLeftSquare /> <span>View all KPIs</span>
                  </Link>
            </div>
      );
};

export default NavigationLink;
